import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CiCdConfigService } from '@app/cicd/ci-cd-config.service';

@Injectable({
  providedIn: 'root'
})
export class CoviInformaService {
  apiUrlCoviInforma: string;
  apiAuthCoviInforma: string;

  constructor(private httpClient: HttpClient, private ciCd: CiCdConfigService) {
    this.ciCdInit();

  }

  ciCdInit() {
    this.ciCd.loadConfigurations().subscribe(({ apis }: any) => {
      const { apiAuthCoviInforma, apiUrlCoviInforma } = apis
      this.apiUrlCoviInforma = apiUrlCoviInforma;
      this.apiAuthCoviInforma = apiAuthCoviInforma;
    });
  }

  getReport(type: 'financiero' | 'comercial' | 'abreviado', nit: string, adminConsult?: string) {
    return this.httpClient.get(this.apiUrlCoviInforma + `informa/report/${type}/${nit}`, {
      headers: {
        authorization: 'Basic ' + btoa(this.apiAuthCoviInforma),
        ...adminConsult ? { user: adminConsult } : {}
        //user: noAuth ? 'informaAdmin' : JSON.parse(localStorage.getItem('user')).user
      }
    })
  }
}
