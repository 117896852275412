import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CoviInformaService } from '@app/services/covi-informa.service';
import { HelperService } from '@app/services/helper.service';
import { RolesService } from '@app/services/roles.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal, { SweetAlertType } from 'sweetalert2';


@Component({
  selector: 'app-reports-pdf',
  templateUrl: './reports-pdf.component.html',
  styleUrls: ['./reports-pdf.component.scss']
})

export class ReportsPdfComponent implements OnInit {

  customDigitValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value.toString() || ''; // Convertir el valor a cadena
      if (value === '') {
        return null; // Si está vacío, no hay error (deja que required lo maneje si es obligatorio)
      }
      const length = value.length;

      return (this.form.get('documentType').value === 'N' && length === 10) || (this.form.get('documentType').value === 'C' && (length === 10 || length === 8))
        ? null // Válido
        : { invalidLength: { value: length } }; // Inválido
    };
  }

  form: FormGroup = this.formBuilder.group({
    documentNumber: ['', Validators.compose([Validators.required, this.customDigitValidator()])],
    documentType: ['', Validators.compose([Validators.required])]
    //documentNumber: ['8909006089', Validators.compose([Validators.required, Validators.maxLength(11)])]
  });
  buttons: any[] = [
    {
      label: 'Financiero',
      value: 'financiero',
      show: false,
    },
    {
      label: 'Comercial',
      value: 'comercial',
      show: false,
    },
    {
      label: 'Abreviado',
      value: 'abreviado',
      show: false,
    },
  ]
  pdfB64: string = '';
  authConsult: string = '';
  constructor(private coviInformaService: CoviInformaService,
    private formBuilder: FormBuilder,
    private loader: NgxSpinnerService,
    public helper: HelperService,
    private roles: RolesService
  ) { }
  ngOnInit() {
    this.findPermissions()
  }
  getReport(type: any) {
    this.pdfB64 = '';
    this.loader.show()
    this.coviInformaService.getReport(type, this.form.get('documentNumber').value, this.authConsult).subscribe({
      next: (data: any) => {
        this.loader.hide()
        console.log(data);
        if (data.ok) {
          this.pdfB64 = data.data[0].informePDF
        } else {
          this.showAlert('warning', 'Advertencia', 'No se ha encontrado información relacionada')
        }
      },
      error: (error) => {
        this.showAlert('warning', 'Advertencia', 'No se ha encontrado información relacionada')
      }
    })
  }
  findPermissions() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.roles.findByUsername(user.user).subscribe((response: any) => {

        response.data.forEach(item => {
          if (item.hasOwnProperty('actions') && item.description === 'auth-consult') {
            if (item.actions.hasOwnProperty('authInforma')) {
              this.authConsult = 'informa';
            }
          }
          if (item.hasOwnProperty('actions') && item.description === 'consult-admin') {
            if (item.actions.hasOwnProperty('adminInforma')) {
              this.authConsult = 'informaAdmin';
            }
          }
          if (item.hasOwnProperty('actions') && item.description === 'informa-report') {
            if (item.actions.hasOwnProperty('reportComercial')) {
              this.buttons.filter(el => el.value === 'comercial')[0].show = true;
            }
            if (item.actions.hasOwnProperty('reportFinancial')) {

              this.buttons.filter(el => el.value === 'financiero')[0].show = true;
            }
            if (item.actions.hasOwnProperty('reportAbbreviated')) {
              this.buttons.filter(el => el.value === 'abreviado')[0].show = true;
            }

          }
        })
      })
    }
  }
  showAlert(typeAlert: SweetAlertType, titleAlert: string, textAlert: string) {
    this.loader.hide()
    Swal.fire({
      type: typeAlert,
      title: titleAlert,
      text: textAlert,
    })
  }


}
