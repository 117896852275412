import { Component, OnInit } from '@angular/core';
import { BackupsTableHeadersService } from '../services/backups-table-headers.service';
import * as moment from 'moment';
import { HelperService } from '@app/services/helper.service';
import { NetsuiteInvoicesService } from './services/netsuite-invoices.service';
import { ChargedAndNonChargedInvoice, NetsuiteInvoiceChargingResponse } from './models/response/netsuite-invoices-response.model';
import { NetsuiteInvoicesHeadersService } from './services/netsuite-invoices-headers.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-netsuite-invoices',
  templateUrl: './netsuite-invoices.component.html',
  styleUrls: ['./netsuite-invoices.component.scss']
})
export class NetsuiteInvoicesComponent implements OnInit {

  public selectedInvoice: string = '';
  rejectionNetsuiteInvoice: any;
  public invoiceInfo: any;
  public inputDate: Date = new Date();
  public maxDate: Date = new Date();
  public minDate: Date = new Date(2000, 1, 1);
  public chargedInvoices: Array<ChargedAndNonChargedInvoice> = [];
  public nonChargedInvoices: Array<ChargedAndNonChargedInvoice> = [];

  dataTable = [];

  constructor(
    public helper: HelperService,
    public netsuiteInvoicesHeaders: NetsuiteInvoicesHeadersService,
    private netsuiteInvoiceService: NetsuiteInvoicesService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modal: ModalService
    ) { }

  ngOnInit() {
  }

  searchInvoices() {
    this.spinner.show();
    const date = moment(this.inputDate).format('YYYYMMDD');
    this.netsuiteInvoiceService.getAffiliateByDocument({fechaProceso: date}).subscribe(
      (response: NetsuiteInvoiceChargingResponse) => {
        this.spinner.hide();
        this.chargedInvoices = response.data[0].facturasCargadas;
        this.nonChargedInvoices = response.data[0].facturasNoCargadas;
        this.toastr.success(response.message, 'Operación exitosa');
      },
      (error) => {
        this.spinner.hide();
        Swal.fire(error.error.message, 'error');
        console.error(error);
        this.chargedInvoices = [];
        this.nonChargedInvoices = [];
      }
    );
  }

  handleGetIntegration3Pkg() {
    this.spinner.show();
    const date = moment(this.inputDate).format('YYYY-MM-DD');
    this.netsuiteInvoiceService.getIntegration3Pkg({fecha: date}).subscribe((response: any) => {
      this.spinner.hide();
      this.toastr.success(response.message, 'Operación exitosa');
    }, (error) => {
      this.spinner.hide();
      Swal.fire(error.error.message, 'error');
    });
  }

  getInvoiceRejection() {
    this.spinner.show();
    this.netsuiteInvoiceService.getInvoiceRejection(this.selectedInvoice).subscribe((response: any) => {
      this.spinner.hide();
      const log = response.data[0];
      this.rejectionNetsuiteInvoice = log;
      console.log(this.rejectionNetsuiteInvoice);
      let mensajeHtml = '';
    }, (error) => {
      this.spinner.hide();
      Swal.fire('Error', error.error.message, 'error');
    });
  }

  formatJson(jsonString: string): string {
    try {
      if (jsonString.startsWith("Respuesta: ")) {
        jsonString = jsonString.replace("Respuesta: ", "").trim();
      }
      const jsonObject = JSON.parse(jsonString);
      return JSON.stringify(jsonObject, null, 2);
    } catch (error) {
      return jsonString;
    }
  }

  public parseJsonValue(jsonString: string, key: string): any {
    try {
      const jsonObject = JSON.parse(jsonString.replace("Respuesta: ", "").trim());
      const value = jsonObject[key];

      if (Array.isArray(value)) {
        return value.map((item, index) => `Item ${index + 1}: ${JSON.stringify(item, null, 2)}`).join('\n');
      }

      if (typeof value === 'object' && value !== null) {
        return JSON.stringify(value, null, 2);
      }

      return value !== undefined ? value : `Clave no encontrada: ${key}`;
    } catch (error) {
      return jsonString;
    }
  }

  public extractJson(jsonString: string): string {
    try {
      jsonString = jsonString.trim();

      if (jsonString.startsWith("Respuesta: ")) {
        jsonString = jsonString.replace("Respuesta: ", "").trim();
      }

      JSON.parse(jsonString);
      return jsonString;
    } catch (error) {
      return jsonString;
    }
  }

  public isValidJson(value: string): boolean {
    try {
      value = this.extractJson(value);
      JSON.parse(value);
      return true;
    } catch (error) {
      return false;
    }
  }

  getObjectKeys(jsonString: string): string[] {
    try {
      const jsonObject = JSON.parse(jsonString.replace('Respuesta: ', '').trim());
      return Object.keys(jsonObject);
    } catch (error) {
      return [];
    }
  }

  handleOutputInvoice(invoice: any) {
    this.selectedInvoice = invoice;
    this.getInvoiceRejection()
  }

  resetSelectedInvoice() {
    this.selectedInvoice = '';
    this.netsuiteInvoiceService.changeInvoiceSelected(null);
  }

}
